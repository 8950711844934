import React, { useState, useCallback, ReactElement, useEffect, useRef } from 'react';
import { createRoot } from 'react-dom/client';
import Terminal, { ColorMode, TerminalInput, TerminalOutput } from 'react-terminal-ui';

import './index.css';

// Helper function to safely handle error messages
const getErrorMessage = (error: unknown): string => {
  if (error instanceof Error) return error.message;
  return String(error);
};

// Function to convert weather codes to descriptions
// Based on Open-Meteo WMO weather codes
const getWeatherDescription = (code: number): string => {
  const weatherCodes: Record<number, string> = {
    0: "Clear sky",
    1: "Mainly clear",
    2: "Partly cloudy",
    3: "Overcast",
    45: "Fog",
    48: "Depositing rime fog",
    51: "Light drizzle",
    53: "Moderate drizzle",
    55: "Dense drizzle",
    56: "Light freezing drizzle",
    57: "Dense freezing drizzle",
    61: "Slight rain",
    63: "Moderate rain",
    65: "Heavy rain",
    66: "Light freezing rain",
    67: "Heavy freezing rain",
    71: "Slight snow fall",
    73: "Moderate snow fall",
    75: "Heavy snow fall",
    77: "Snow grains",
    80: "Slight rain showers",
    81: "Moderate rain showers",
    82: "Violent rain showers",
    85: "Slight snow showers",
    86: "Heavy snow showers",
    95: "Thunderstorm",
    96: "Thunderstorm with slight hail",
    99: "Thunderstorm with heavy hail"
  };

  return weatherCodes[code] || "Unknown";
};

const getCoordinatesFromNominatim = async (location: string): Promise<[number, number] | null> => {
  try {
    const response = await fetch(
      `https://nominatim.openstreetmap.org/search?format=json&q=${encodeURIComponent(location)}`,
      {
        headers: {
          'Accept': 'application/json',
          'User-Agent': 'YourTerminalApp/1.0' // Required by Nominatim's usage policy
        }
      }
    );
    
    const data = await response.json();
    
    if (data && data.length > 0) {
      const lat = parseFloat(data[0].lat);
      const lon = parseFloat(data[0].lon);
      return [lat, lon];
    }
    
    return null;
  } catch (error) {
    console.error('Nominatim geocoding error:', error);
    return null;
  }
};

// List of available commands for tab completion
const availableCommands = [
  'whoami',
  'linkedin',
  'ls',
  'weather',
  'localtime',
  'clear',
  'help'
];

const commands = {
  // Returns IP address
  whoami: async (): Promise<ReactElement> => {
    try {
      const response = await fetch("https://api.ipify.org?format=json");
      const data = await response.json();
      return <TerminalOutput>{data.ip}</TerminalOutput>;
    } catch (error) {
      // Convert error to string safely
      const errorMsg = getErrorMessage(error);
      return <TerminalOutput>Error fetching IP: {errorMsg}</TerminalOutput>;
    }
  },

  linkedin: (): ReactElement[] => {
    window.open("https://www.linkedin.com/in/luke-walker-9b29476/", "_blank");
    return [
      <TerminalOutput key="linkedin-opening">Opening LinkedIn profile...</TerminalOutput>
    ];
  },
  
  // List files (former help command)
  ls: (): ReactElement[] => {
    return [
      <TerminalOutput key="ls-output">whoami  linkedin  weather  localtime  clear</TerminalOutput>
    ];
  },
  
  // Get weather based on location
  weather: async (location?: string): Promise<ReactElement[]> => {
    try {
      let lat, lon;
      let locationName = location || "Current location";
      
      if (!location) {
        // Use browser's geolocation if no location provided
        const position = await new Promise<GeolocationPosition>((resolve, reject) => {
          navigator.geolocation.getCurrentPosition(resolve, reject);
        });
        
        lat = position.coords.latitude;
        lon = position.coords.longitude;
      } else {
        try {
          const coordinates = await getCoordinatesFromNominatim(location);
          
          if (coordinates && coordinates.length === 2) {
            lat = coordinates[0];
            lon = coordinates[1];
          } else {
            return [<TerminalOutput key="weather-error">Location not found</TerminalOutput>];
          }
        } catch (error) {
          return [<TerminalOutput key="weather-error">Error getting coordinates: {getErrorMessage(error)}</TerminalOutput>];
        }
      }
      
      // Use Open-Meteo API to get weather
      const weatherUrl = `https://api.open-meteo.com/v1/forecast?latitude=${lat}&longitude=${lon}&current=temperature_2m,relative_humidity_2m,wind_speed_10m,weather_code`;
      const weatherResponse = await fetch(weatherUrl);
      const weatherData = await weatherResponse.json();
      
      if (!weatherData.current) {
        return [<TerminalOutput key="weather-error">Weather data not available</TerminalOutput>];
      }
      
      // Get the weather description based on weather code
      const weatherCode = weatherData.current.weather_code;
      const weatherDesc = getWeatherDescription(weatherCode);
      
      // Extract current weather data
      const temp = Math.round(weatherData.current.temperature_2m);
      const humidity = weatherData.current.relative_humidity_2m;
      const windSpeed = weatherData.current.wind_speed_10m;
      
      return [
        <TerminalOutput key={`weather-location-${Date.now()}`}>Location: {locationName}</TerminalOutput>,
        <TerminalOutput key={`weather-temp-${Date.now()}`}>Temperature: {String(temp)}°C</TerminalOutput>,
        <TerminalOutput key={`weather-desc-${Date.now()}`}>Conditions: {weatherDesc}</TerminalOutput>,
        <TerminalOutput key={`weather-humidity-${Date.now()}`}>Humidity: {humidity}%</TerminalOutput>,
        <TerminalOutput key={`weather-wind-${Date.now()}`}>Wind Speed: {windSpeed} km/h</TerminalOutput>
      ];
    } catch (error) {
      const errorMsg = getErrorMessage(error);
      return [<TerminalOutput key="weather-error">Error fetching weather: {errorMsg}</TerminalOutput>];
    }
  },
  
  // Get local time in a city
  localtime: async (location?: string): Promise<ReactElement[]> => {
    if (!location) {
      return [<TerminalOutput key="localtime-error">Please provide a location (e.g., localtime Sydney, Australia)</TerminalOutput>];
    }
    
    try {
      // Use WorldTimeAPI to get the time for a location
      const timeUrl = `https://worldtimeapi.org/api/timezone/`;
      
      // First fetch all available timezones
      const timezonesResponse = await fetch(timeUrl);
      const timezones = await timezonesResponse.json();
      
      // Find a matching timezone based on the location provided
      const lowercaseLocation = location.toLowerCase();
      const matchingTimezone = timezones.find((timezone: string) => 
        timezone.toLowerCase().includes(lowercaseLocation.split(' ')[0]) // Match on first word
      );
      
      if (!matchingTimezone) {
        return [<TerminalOutput key="localtime-error">Location not found in timezone database</TerminalOutput>];
      }
      
      // Get time for the matching timezone
      const timeResponse = await fetch(`${timeUrl}${matchingTimezone}`);
      const timeData = await timeResponse.json();
      
      // Format the datetime string
      const datetime = new Date(timeData.datetime);
      const formattedTime = datetime.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
      const formattedDate = datetime.toLocaleDateString();
      
      return [
        <TerminalOutput key={`localtime-location-${Date.now()}`}>Local time in {location}:</TerminalOutput>,
        <TerminalOutput key={`localtime-time-${Date.now()}`}>{formattedTime} on {formattedDate}</TerminalOutput>,
        <TerminalOutput key={`localtime-timezone-${Date.now()}`}>Timezone: {timeData.timezone}</TerminalOutput>
      ];
    } catch (error) {
      const errorMsg = getErrorMessage(error);
      return [<TerminalOutput key="localtime-error">Error fetching local time: {errorMsg}</TerminalOutput>];
    }
  }
};

const TerminalController = (props = {}) => {
  const [lineData, setLineData] = useState<ReactElement[]>([
    <TerminalOutput key="welcome">7c0&#128075;</TerminalOutput>,
  ]);
  
  // State to track the current input
  const [currentInput, setCurrentInput] = useState<string>('');
  
  // State to track if a command is currently executing
  const [isExecuting, setIsExecuting] = useState<boolean>(false);
  
  // Create a ref to the terminal element for focusing
  const terminalRef = useRef<HTMLDivElement>(null);

  // Tab completion function
  const handleTabCompletion = useCallback(() => {
    if (!currentInput || isExecuting) return;
    
    // Only attempt to complete commands, not arguments
    if (currentInput.includes(' ')) return;
    
    // Find matching commands
    const matchingCommands = availableCommands.filter(cmd => 
      cmd.startsWith(currentInput.toLowerCase())
    );
    
    if (matchingCommands.length === 1) {
      // If exactly one match, complete the command
      setCurrentInput(matchingCommands[0]);
      
      // Update lineData to show the completion suggestion
      setLineData(prev => [
        ...prev, 
        <TerminalOutput key={`tab-completion-${Date.now()}`}>
          Tab completion: {matchingCommands[0]}
        </TerminalOutput>
      ]);
    } else if (matchingCommands.length > 1) {
      // If multiple matches, show options
      setLineData(prev => [
        ...prev, 
        <TerminalOutput key={`tab-completion-${Date.now()}`}>
          Available completions: {matchingCommands.join('  ')}
        </TerminalOutput>
      ]);
      
      // Find common prefix if any
      let commonPrefix = currentInput;
      let pos = currentInput.length;
      let allMatch = true;
      
      while (allMatch && pos < matchingCommands[0].length) {
        const char = matchingCommands[0][pos];
        for (let i = 1; i < matchingCommands.length; i++) {
          if (matchingCommands[i][pos] !== char) {
            allMatch = false;
            break;
          }
        }
        if (allMatch) {
          commonPrefix += char;
          pos++;
        }
      }
      
      if (commonPrefix !== currentInput) {
        setCurrentInput(commonPrefix);
      }
    }
  }, [currentInput, isExecuting]);

  // Set up the keyboard event listener for Tab key
  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      // Only handle tab completion if not currently executing a command
      if (event.key === 'Tab' && !isExecuting) {
        event.preventDefault(); // Prevent focusing on the next element
        handleTabCompletion();
      }
    };
    
    // Add event listener
    document.addEventListener('keydown', handleKeyDown);
    
    // Clean up
    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [currentInput, isExecuting, handleTabCompletion]);  // Re-run when currentInput or isExecuting changes

  async function onInput(input: string) {
    // If a command is already executing, don't process new input
    if (isExecuting) {
      return;
    }
    
    // Update current input
    setCurrentInput('');
    
    // Create a new array with the user input
    const timestamp = Date.now();
    const inputElement = <TerminalInput key={`input-${timestamp}`}>{input}</TerminalInput>;
    let ld = [...lineData, inputElement];
    setLineData(ld); // Update immediately with the input line
    
    // Parse the command (first word) and arguments (rest of the input)
    const trimmedInput = input.trim();
    const [command, ...args] = trimmedInput.split(' ');
    const normalizedCommand = command.toLowerCase();
    const argsString = args.join(' ');
    
    // Add pending indicator for async commands
    if (['whoami', 'weather', 'localtime'].includes(normalizedCommand)) {
      setIsExecuting(true);
      setLineData(prev => [...prev, <TerminalOutput key={`pending-${Date.now()}`}>Executing command...</TerminalOutput>]);
    }
    
    // Handle the command with a switch statement
    try {
      switch (normalizedCommand) {
        case 'clear':
          setLineData([]);
          break;
          
        case 'whoami':
        case './whoami':
          try {
            const output = await commands.whoami();
            setLineData(prev => {
              // Remove the pending message and add the output
              const filtered = prev.filter(item => 
                !(React.isValidElement(item) && 
                  item.key && 
                  String(item.key).startsWith('pending-'))
              );
              return [...filtered, React.cloneElement(output, { key: `output-${Date.now()}` })];
            });
          } catch (error) {
            // Convert error to string safely
            const errorMsg = getErrorMessage(error);
            const errorOutput = <TerminalOutput key={`error-${Date.now()}`}>Error: {errorMsg}</TerminalOutput>;
            setLineData(prev => {
              // Remove the pending message and add the error
              const filtered = prev.filter(item => 
                !(React.isValidElement(item) && 
                  item.key && 
                  String(item.key).startsWith('pending-'))
              );
              return [...filtered, errorOutput];
            });
          } finally {
            setIsExecuting(false);
          }
          break;
        
        case 'linkedin':
        case './linkedin':
          const linkedinOutputs = commands.linkedin();
          setLineData(prev => [...prev, ...linkedinOutputs]);
          break;
        
        case 'ls':
        case './ls':
          const lsOutputs = commands.ls();
          setLineData(prev => [...prev, ...lsOutputs]);
          break;
                  
        case 'weather':
        case './weather':
          try {
            const weatherOutputs = await commands.weather(argsString || undefined);
            setLineData(prev => {
              // Remove the pending message and add the outputs
              const filtered = prev.filter(item => 
                !(React.isValidElement(item) && 
                  item.key && 
                  String(item.key).startsWith('pending-'))
              );
              return [...filtered, ...weatherOutputs];
            });
          } catch (error) {
            const errorMsg = getErrorMessage(error);
            const errorOutput = <TerminalOutput key={`error-${Date.now()}`}>Error fetching weather: {errorMsg}</TerminalOutput>;
            setLineData(prev => {
              // Remove the pending message and add the error
              const filtered = prev.filter(item => 
                !(React.isValidElement(item) && 
                  item.key && 
                  String(item.key).startsWith('pending-'))
              );
              return [...filtered, errorOutput];
            });
          } finally {
            setIsExecuting(false);
          }
          break;
          
        case 'localtime':
        case './localtime':
          try {
            const localtimeOutputs = await commands.localtime(argsString || undefined);
            setLineData(prev => {
              // Remove the pending message and add the outputs
              const filtered = prev.filter(item => 
                !(React.isValidElement(item) && 
                  item.key && 
                  String(item.key).startsWith('pending-'))
              );
              return [...filtered, ...localtimeOutputs];
            });
          } catch (error) {
            const errorMsg = getErrorMessage(error);
            const errorOutput = <TerminalOutput key={`error-${Date.now()}`}>Error fetching local time: {errorMsg}</TerminalOutput>;
            setLineData(prev => {
              // Remove the pending message and add the error
              const filtered = prev.filter(item => 
                !(React.isValidElement(item) && 
                  item.key && 
                  String(item.key).startsWith('pending-'))
              );
              return [...filtered, errorOutput];
            });
          } finally {
            setIsExecuting(false);
          }
          break;
        
        case '':
          // Handle empty input
          break;
          
        default:
          // Command not recognized
          const unknownOutput = <TerminalOutput key={`unknown-${Date.now()}`}>Unknown command: {normalizedCommand}</TerminalOutput>;
          setLineData(prev => [...prev, unknownOutput]);
          break;
      }
    } catch (error) {
      const errorMsg = getErrorMessage(error);
      const errorOutput = <TerminalOutput key={`error-${Date.now()}`}>Unexpected error: {errorMsg}</TerminalOutput>;
      setLineData(prev => [...prev, errorOutput]);
      setIsExecuting(false);
    }
  }
  
  // Custom input handler to track current input
  const handleInputChange = (input: string) => {
    setCurrentInput(input);
  };
  
  return (
    <div className="container" ref={terminalRef}>
      <Terminal
        name='lwalker.me'
        colorMode={ColorMode.Dark}
        onInput={onInput}
        prompt="$"
        currentInput={currentInput}
        onInputChange={handleInputChange}
        disableOnProcess={isExecuting} // Disable input when executing a command
        TopButtonsPanel={()=> null}
      >
        {lineData}
      </Terminal>
    </div>
  );
};

const rootElement = document.getElementById('terminal');
if (rootElement) {
  const root = createRoot(rootElement);
  root.render(
    <React.StrictMode>
      <TerminalController />
    </React.StrictMode>
  );
} else {
  console.error("Element with ID 'terminal' not found in the document.");
}
